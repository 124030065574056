@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.about-container {
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
    background: #fffdfa;
}

.about-heading {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3em;
    color: #3c114d;
}

.product-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 100px;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 963px) {
    .product-wrapper {
        justify-content: center;
    }
}
