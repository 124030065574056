@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

.landing-container {
    width: 100%;
    height: 100vh;
    background: #fffdfa;
    text-align: center;
    color: white;
    top: 0;
}

.landing-content-container {
    display: flex;
}

.logo-container {
    width: 100%;
    text-align: left;
}

.rammyaa-logo {
    width: 20%;
}

.tagline-container {
    width: 50%;
}

.tagline {
    color: #3c114d;
    font-family: "Rubik Mono One", monospace;
    font-style: normal;
    font-size: 5rem;
}

.vector-img {
    display: block;
    width: 50%;
}

.rammyaa-vector {
    width: 90%;
}

.explore-btn {
    cursor: pointer;
    padding: 0.8em 1em;
    border-radius: 50%;
    border: 2px solid white;
    font-size: 1em;
    background: #5e5d7d;
    color: white;
}

@media (max-width: 900px) {
    .landing-content-container {
        display: block;
    }

    .tagline-container {
        width: 100% !important;
    }

    .vector-img {
        display: none;
    }

    .rammyaa-logo {
        width: 35%;
    }
}

@media (max-width: 620px) {
    .tagline {
        font-size: 4rem;
    }

    .vector-img {
        display: block;
        width: 100%;
    }

    .rammyaa-vector {
        width: 50%;
    }
}

@media (max-width: 492px) {
    .tagline {
        font-size: 3rem;
    }
}

@media (max-width: 370px) {
    .tagline {
        font-size: 2rem;
    }
}
