.product-container {
    width: 350px;
    height: 490px;
    border-radius: 6px;
    text-align: left;
    padding: 1em;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 3em;
    position: relative;
}

.image-container {
    height: 80%;
    width: 100%;
}

.card-heading {
    font-size: 1.2em;
    font-weight: 600;
}

.price-container {
    display: flex;
    font-size: 1.5em;
    position: absolute;
    bottom: 0;
}
