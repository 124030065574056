.footer-container {
    background: black;
    color: white;
    bottom: 0;
    width: 100%;
    padding: 10px;
}

.footer-links {
    color: white;
    text-decoration: none;
}

.footer-rammyaa-logo {
    width: 15%;
}

.footer-content-container {
    display: flex;
}

.footer-column-1 {
    width: 40%;
    padding-left: 1.5em;
}

.footer-column-2 {
    width: 30%;
}

.footer-column-3 {
    width: 30%;
}

@media (max-width: 720px) {
    .footer-rammyaa-logo {
        width: 25%;
    }
}

@media (max-width: 520px) {
    .footer-rammyaa-logo {
        width: 35%;
    }

    .footer-content-container {
        display: block;
    }

    .footer-column-1 {
        width: 100%;
    }

    .footer-column-2 {
        padding-left: 1.5em;
        width: 100%;
    }

    .footer-column-3 {
        padding-left: 1.5em;
        padding-top: 1em;
        width: 100%;
    }
}
